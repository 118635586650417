import { useEffect } from 'react';

const useScrollToAnchor = (delay = 1000) => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, delay);
      }
    }
  }, [delay]);
};

export default useScrollToAnchor;
