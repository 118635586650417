import React, { useEffect, useState } from 'react';
import jsdata from '../pages-fsd/OnlineJsPage/model/online.data';
import data from '../pages-fsd/WebDevelopmentPage/model/webdev.data';
import useScrollToAnchor from '../helpers/useScrollToAnchor';
import HeadMaker from '../HeadMaker';
import Layout from '../shared/Layout/Layout';
import { getProgramGroups } from '../api/strapi';
import { parseDate } from '../helpers/lib';
import { useStudyToolsQuery } from '../app/queries/studyTools.query';
import { useDevPageQuery } from '../app/queries/webdevpage.query';
import { useEcbWorksQuery } from '../app/queries/howecbworks.query';
import {
  StudyTools,
  FormatBootcamp,
  BenefitsStrip,
  DevIntro,
  CommonJsStructure,
  HowDoesBootcampWork,
  EducationFormat,
  Questions,
} from '../widgets';

const mainData = {
  leadPage: 'Веб_Разработка',
  program: 'Javascript',
  tags: ['веб-дев'],
};

const leadData = {
  structure: {
    ...mainData,
    eventName: 'Получить подробную программу',
    leadBlock: 'Структура программы',
    leadAction: 'Получить подробную программу',
  },
  question: {
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
    leadPage: 'Веб_Разработка',
    program: 'JS',
  },
};

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

function WebDevelopmentPage() {
  useScrollToAnchor(700);
  const { logosData } = useStudyToolsQuery();
  const { devPageImgs } = useDevPageQuery();
  const { ecbWorksImgs } = useEcbWorksQuery();
  const [prepGroup, setPrepGroup] = useState(null);

  useEffect(() => {
    const ac = new AbortController();
    getProgramGroups(ac, 'PREPARE_JS').then(
      (groups) => groups && setPrepGroup(groups[0]),
    );
  }, []);
  return (
    <Layout>
      <div style={{ overflow: 'hidden' }}>
        <DevIntro />
        <BenefitsStrip benefits={data.benefits} />
      </div>
      <CommonJsStructure
        {...data.structure}
        leadData={leadData.structure}
        backgroundImg="common-programs"
      />
      <div style={{ overflow: 'hidden' }}>
        <StudyTools {...jsdata.tools} logosData={logosData} />
        <HowDoesBootcampWork
          images={ecbWorksImgs}
          {...data.howDoesBootcampWork}
        />

        <EducationFormat {...data.educationFormat} images={devPageImgs} />
        <FormatBootcamp
          {...data.formatBootcamp}
          startDate={parseDate(prepGroup?.startDate)?.slice(1).join(' ')}
          priceData={prepGroup?.price}
        />
        <Questions {...data.questions} extraLeadData={leadData.question} />
      </div>
    </Layout>
  );
}

export default WebDevelopmentPage;
