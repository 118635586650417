const data = {
  seo: {
    title: 'Курсы веб-разработки в Эльбрус Буткемп | Программа обучения',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Изучите веб-разработку на курсах от школы программирования Эльбрус Буткемп. Получите знания и навыки от опытных преподавателей, чтобы стать профессионалом в сфере веб-разработки. Запишитесь на курс прямо сейчас!',
      },
      {
        property: 'og:title',
        name: null,
        content: 'Курсы веб-разработки в Эльбрус Буткемп | Программа обучения',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Изучите веб-разработку на курсах от школы программирования Эльбрус Буткемп. Получите знания и навыки от опытных преподавателей, чтобы стать профессионалом в сфере веб-разработки. Запишитесь на курс прямо сейчас!',
      },
      {
        property: 'og:image',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/09.JPG',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'og:type', name: null, content: 'product' },
    ],
  },
  benefits: [
    {
      type: 'fire',
      text: 'Актуальная программа: HTML, CSS, TypeScript, JavaScript,  Node js, Express, React, Redux, PostgreSQL, Git',
    },
    {
      type: 'communication',
      text: 'Живые лекции и разбор кода ментором',
    },
    {
      type: 'career',
      text: 'Помощь в трудоустройстве: 40 часов с карьерными коучами',
    },
    {
      type: 'diploma',
      text: 'Диплом о профессиональной переподготовке установленного образца',
    },
  ],
  structure: {
    title: ['Программа обучения', ' по Веб-разработке'],
    subtitle: '',
    minHeight: '193px',
    list: [
      {
        period: '1-3 неделя',
        phase: 'Фаза 0',
        description:
          'Терминал, Git, основы HTML/CSS, основы JavaScript. Занятость 6-8 часов в день в онлайн формате.',
        backgroundColor: 'kit.background.gray',
        marginTop: '0',
        minHeight: '138px',
      },
      {
        period: '4-6 неделя',
        phase: 'Фаза 1',
        description:
          'JavaScript, unit-тестирование, алгоритмы, асинхронность, ООП, знакомство с SQL и ORM.',
        backgroundColor: 'kit.background.lightpurple',
        marginTop: '-178px',
        minHeight: '138px',
      },
      {
        period: '7-9 неделя',
        phase: 'Фаза 2',
        description:
          'Back-end (Node.js, Express), SQL/ORM, REST API, React и JWT. Два проекта для портфолио.',
        backgroundColor: 'kit.background.darkgray',
        marginTop: '-178px',
        minHeight: '138px',
      },
      {
        period: '10-12 неделя',
        phase: 'Фаза 3',
        description:
          'TypeScript (React + Redux Toolkit). Связь front-end с back-end и БД. Docker на платформе Selectel.',
        backgroundColor: 'kit.background.lightpurple',
        marginTop: '-176px',
        minHeight: '138px',
      },
      {
        period: '5 дней',
        phase: 'Карьерная неделя',
        description:
          'Стратегия по поиску работы, составление резюме, подготовка к собеседованиям.',
        backgroundColor: 'kit.background.middlepurple',
        marginTop: '-174px',
        minHeight: '138px',
      },
    ],
    ymEvents: {
      programFormSubmit: 'WebDevProgram',
    },
    myTargetEvents: {
      programFormSubmit: 'WebDevProgram',
    },
  },
  howDoesBootcampWork: {
    image: 'howBootcampWorks/500codingHours',
    pointsLists: [
      {
        title: 'Из чего состоит обучение',
        list: [
          'Живые лекции и обратная связь от преподавателя',
          'Код-ревью: проверка кода преподавателем',
          'Работа над проектами от заказчика',
          'Подготовка к собеседованиям вместе с карьерным коучем',
        ],
      },
      {
        title: 'Что получает выпускник',
        list: [
          'Профессия: разработчик JavaScript, TypeScript',
          'Портфолио на GitHub: 2 групповых, 1 индивидуальный и 10 учебных проектов',
          'Диплом о профессиональной переподготовке установленного образца',
          'Доступ к закрытому комьюнити разработчиков',
        ],
      },
    ],
  },
  educationFormat: {
    title: 'Формат обучения',
    list: [
      {
        name: 'Онлайн',
        profession: 'Веб разработчик',
        location: 'ONLINE',
        duration: '12 недель',
        href: '/online-js',
        ymEvent: 'WebDevOnlineMore',
        myTargetEvent: 'WebDevOnlineMore',
      },
      {
        name: 'Санкт-Петербург',
        extraInfo: 'Офлайн',
        profession: 'Веб разработчик',
        location: 'SPB',
        duration: '12 недель',
        href: '/spb-js',
        ymEvent: 'WebDevSpbMore',
        myTargetEvent: 'WebDevSpbMore',
      },
      {
        name: 'Москва',
        extraInfo: 'Офлайн',
        profession: 'Веб разработчик',
        location: 'MSK',
        duration: '12 недель',
        href: '/msk-js',
        ymEvent: 'WebDevMskMore',
        myTargetEvent: 'WebDevMskMore',
      },
    ],
  },
  formatBootcamp: {
    title: 'Можно попробовать сейчас, а выбрать формат Буткемпа потом',
    preCourseTitle: 'Основы JavaScript•Онлайн',
    description:
      'Пройдите короткий курс занятий с ментором, чтобы начать программировать и почувствовать атмосферу Буткемпа',
    duration: '2 недели',
    pointsList: [
      '10 живых занятий с ментором',
      'Индивидуальная обратная связь по задачам от ментора',
      'Поддержка в закрытом чате разработчиков',
    ],
    link: {
      url: '/payment/?leartype=js.prepare.ru',
      title: 'Купить',
    },
  },
  questions: {
    message: 'Готовы ответить на ваши вопросы',
    actions: {
      first: {
        title: 'Оставить заявку',
      },
    },
    ymEvents: {
      consultationFormSubmit: 'WebDevBootomConsultation',
    },
  },
};

export default data;
